import React from 'react';
import Layout from 'components/layout';


const NotFoundPage = () => (
  <Layout title="Sidan hittades inte">
    <h1>Sidan hittades inte</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
